// src/app/shared/services/navigation.service.ts

import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { Location } from '@angular/common';

@Injectable({
	providedIn: 'root',
})
export class NavigationService {
	constructor(private router: Router, private location: Location) {}

	navigateTo(path: string[], extras?: NavigationExtras): Promise<boolean> {
		return this.router.navigate(path, extras);
	}

	navigateToLogin(): Promise<boolean> {
		return this.router.navigate(['/auth/login']);
	}

	navigateToRegister(): Promise<boolean> {
		return this.router.navigate(['/auth/self-enrollment']);
	}

	navigateToActivateAccount(
		encodedUsername: string,
		encodedLegalEntityId: string
	): Promise<boolean> {
		return this.router.navigate(['auth/self-enrollment/activate'], {
			queryParams: { u: encodedUsername, le: encodedLegalEntityId },
		});
	}

	navigateToCreatePassword(encodedServiceKey: string): Promise<boolean> {
		return this.router.navigate(['auth/create-password'], {
			queryParams: { sk: encodedServiceKey },
		});
	}

	navigateToTermsConditions(): Promise<boolean> {
		return this.router.navigate(['/auth/self-enrollment/terms-conditions']);
	}

	// Navigation après authentification
	navigateToHome(): Promise<boolean> {
		return this.router.navigate(['/home']);
	}

	navigateToProfile(): Promise<boolean> {
		return this.router.navigate(['/profile']);
	}

	// Navigation générale
	goBack(): void {
		this.location.back();
	}

	goForward(): void {
		this.location.forward();
	}

	refresh(): void {
		this.router.navigate([this.router.url]);
	}

	// Méthodes utilitaires
	getCurrentUrl(): string {
		return this.router.url;
	}

	isCurrentUrl(url: string): boolean {
		return this.router.url === url;
	}

	// Navigation avec conservation de l'historique
	navigateByUrl(url: string): Promise<boolean> {
		return this.router.navigateByUrl(url);
	}

	// Navigation avec remplacement de l'URL (sans ajouter à l'historique)
	replaceUrl(path: string[]): Promise<boolean> {
		return this.router.navigate(path, { replaceUrl: true });
	}

	navigateToCreatePasswordForForgottenPassword(
		encodedServiceKey: string,
		userName: string
	): Promise<boolean> {
		return this.router.navigate(['auth/create-password'], {
			queryParams: { sk: encodedServiceKey, userName },
		});
	}
}
